import { template as template_a015c002310f4f8e8d888b9955d0883e } from "@ember/template-compiler";
const FKLabel = template_a015c002310f4f8e8d888b9955d0883e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
