import { template as template_ba31b9e93c214f3a90cee44479726910 } from "@ember/template-compiler";
const WelcomeHeader = template_ba31b9e93c214f3a90cee44479726910(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
